import axios from 'axios';
import {
  dateFormat,
  transformDateToDays,
  transformDateToHours,
  transformDateToMinutes,
} from '../utils/transformData/transformDate';

export const reportingFormAPI = {
  sendReportData(event: any, captchaDigest: string, formType: string, files: any[]) {
    const formData = new FormData();
    formData.append('xnQsjsdp', 'edbsn8db19f04fc6284603888d5eaad2967cf');
    formData.append('xmIwtLD', 'edbsnbc8fb76c19ee5ad93698ea0063962542acb51300d8884641190cb405bb797afe');
    formData.append('xJdfEaS', captchaDigest);
    formData.append('actionType', 'Q2FzZXM=');
    formData.append('returnURL', 'https://google.com');
    formData.append('Contact Name', event.name);
    formData.append('Email', event.email);
    formData.append('Phone', event.phoneNumber);
    formData.append('Due Dateampm', dateFormat(event.datetime));
    formData.append('Due Date', transformDateToDays(event.datetime));
    formData.append('Due Datehour', transformDateToHours(event.datetime));
    formData.append('Due Dateminute', transformDateToMinutes(event.datetime));
    formData.append('Due avtomatic', new Date() as unknown as string);
    formData.append('Subject', event.subject);
    formData.append('Description', event.description);
    if (event.deviceSw) {
      formData.append('Devices', event.deviceSw.name);
    }

    if (event.deviceHw) {
      formData.append('Devices', event.deviceHw.name);
    }

    if (formType === 'Desk-GreenWay') {
      if (event.locationPl) {
        formData.append('Installations', event.locationPl.name);
      }

      if (event.locationSk) {
        formData.append('Installations', event.locationSk.name);
      }
      formData.append('Site Name', 'Wattbooster');
      formData.append('Company Name', 'GreenWay');
    } else {
      formData.append('Installations', '');
      formData.append('Site Name', event.siteName);
      formData.append('Company Name', event.companyName);
    }

    formData.append('attachment_1', files[0]);
    if (files[1]) formData.append('attachment_2', files[1]);
    if (files[2]) formData.append('attachment_3', files[2]);
    if (files[3]) formData.append('attachment_4', files[3]);
    if (files[4])  formData.append('attachment_5', files[4]);
    formData.append('zsWebFormCaptchaWord', event.captcha);
    return axios.post('support/WebToCase', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => response.data);
  },
  getCaptcha() {
    return axios.get(`support/GenerateCaptcha?action=getNewCaptcha&_=${+new Date().getTime()}`).then((response) => response.data);
  },
};
