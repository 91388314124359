import { ZohoDataDto } from '../../types/models/zoho-data.dto';

export const devices = {
  hw: [
    { name: 'Container or mechanical parts', id: 1 },
    { name: 'Inverter', id: 2 },
    { name: 'Battery', id: 3 },
    { name: 'Electric and data communication inc.iMP, PMS, GSM', id: 4 },
    { name: 'Antenna system', id: 5 },
    { name: 'Cooling or heating system', id: 6 },
  ] as ZohoDataDto[],
  sw: [
    { name: 'Cloud', id: 1 },
    { name: 'IMP', id: 2 },
    { name: 'PMS', id: 3 },
    { name: 'Air conditioning', id: 4 },
    { name: 'Net, routing', id: 5 },
  ] as ZohoDataDto[],
};
