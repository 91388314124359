import { ZohoDataDto } from '../../types/models/zoho-data.dto';

export const locations = {
  sk: [
    { name: 'Trenčin Wattbooster', id: 1 },
    { name: 'Ružomberok Wattsbooster', id: 2 },
    { name: 'Lučenec OC Galeria EMS', id: 3 },
    { name: 'Prešov OC Solivaria EMS', id: 4 },
    { name: 'Žilina OC Duben EMS', id: 5 },
  ] as ZohoDataDto[],
  pl: [
    { name: 'Ciechocinek Wattbooster', id: 1 },
    { name: 'Dabrowa Wielka Wschod (Lucmierz) Wattbooster', id: 2 },
    { name: 'Gdansk Wattbooster', id: 3 },
    { name: 'Grodkow Wattbooster', id: 4 },
    { name: 'Chojny (Lodz) Wattbooster', id: 5 },
    { name: 'Katy Wroclawskie EMS', id: 6 },
    { name: 'Kopytkowo Wattbooster', id: 7 },
    { name: 'Miawa Wattbooster', id: 8 },
    { name: 'Posilek Wattbooster', id: 9 },
    { name: 'Przylek Wattbooster', id: 10 },
    { name: 'Przysiecz (Strelce Opolskie)', id: 11 },
  ] as ZohoDataDto[],
};
