import React from 'react';
import {
  Alert, AlertTitle, Autocomplete, Divider, FormControl,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { yupResolver } from '@hookform/resolvers/yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import styles from './ReportingForm.module.css';
import { validationSchema } from '../../utils/validators/reportingForm';

import { reportingFormAPI } from '../../api/reporting-form';
import { devices } from '../../utils/dummyData/devices';
import { locations } from '../../utils/dummyData/installations';

export const ReportingForm = () => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    register, control, handleSubmit, reset, formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [formType, setFormType] = React.useState<'Desk-GreenWay' | 'Desk' | null>(null);

  const [reportMode, setReportMode] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false)

  const [error, setError] = React.useState<string | null>(null);
  const [fileError, setFileError] = React.useState<boolean>(false);

  const [issueDate, setIssueDate] = React.useState<Date | null>(null);
  const [file, setFile] = React.useState<any | []>([]);

  const [submittedMessage, setSubmittedMessage] = React.useState<string | null>(null);

  const [location, setLocation] = React.useState<string | null>(null);
  const [device, setDevice] = React.useState<string | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [locationSk, setLocationSk] = React.useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [locationPl, setLocationPl] = React.useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deviceHw, setDevicesHw] = React.useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deviceSw, setDevicesSw] = React.useState<string | null>(null);

  const [captchaUrl, setCaptchaUrl] = React.useState<any>(null);
  const [captchaDigest, setCaptchaDigest] = React.useState<any>(null);

  const refreshCaptcha = () => {
    reportingFormAPI.getCaptcha().then((res) => {
      setCaptchaUrl(res.captchaUrl);
      setCaptchaDigest(res.captchaDigest);
    });
  };

  async function onSubmit(event: any) {
    try {
      setLoading(true)
      const regex = /incorrect/;
      await reportingFormAPI.sendReportData(event, captchaDigest, formType!, file).then((res) => {
        if (regex.test(res)) {
          setError('Incorrect captcha');
          refreshCaptcha();
          reset({ captcha: '', datetime: event.datetime, file: event.file });
          setLoading(false)
        } else {
          setLoading(false)
          setSubmittedMessage('Thank you for submitting a support request. You should receive an email shortly confirming your case.');
        }
      });
    } catch (e) {
      setError('Sorry, there was a problem. Please try again.');
    }
  }

  React.useEffect(() => {
    if (window.location.href.includes('greenway')) {
      setFormType('Desk-GreenWay');
    } else {
      setFormType('Desk');
    }
    refreshCaptcha();
  }, []);
  return (
    <Grid className={styles.root_active}>
      <Grid className={!error ? styles.modal : `${styles.modal} ${styles.modal_active}`}>
        <Alert severity="error" className={!error ? styles.alert : `${styles.alert} ${styles.alert_active}`}>
          <AlertTitle>Error</AlertTitle>
          <Grid>
            <Grid>{error}</Grid>
            <Button
              onClick={() => {
                setError(null);
              }}
              sx={{
                position: 'fixed', bottom: '10px', right: '10px', mt: 5,
              }}
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
        </Alert>
      </Grid>
      <Grid className={!submittedMessage ? styles.modal : `${styles.modal} ${styles.modal_active}`}>
        <Alert severity="success"
               className={!submittedMessage ? styles.alert : `${styles.alert} ${styles.alert_active}`}>
          <AlertTitle>Success</AlertTitle>
          <Grid>
            <Grid>{submittedMessage}</Grid>
            <Grid>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                sx={{
                  position: 'fixed', bottom: '10px', right: '10px', mt: 5,
                }}
                variant="outlined"
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Alert>
      </Grid>
      <Grid className={styles.root}>
        <Typography variant="h4">
          Service
          {formType === 'Desk-GreenWay' ? '  Desk - GreenWay' : ' Desk'}
        </Typography>
        <Grid container justifyContent="center">
          {formType === 'Desk-GreenWay' ? (
              <Typography variant="h6" sx={{ p: 3, m: 1 }}>
                The services desk (Wattstor - GreenWay) serves the purpose of
                effectively resolving the problems of the Wattstor devices.
              </Typography>
            )
            : <Typography variant="h6" sx={{ p: 3, m: 1 }}>The services desk serves the purpose of effectively resolving
              the problems of the Wattstor devices.</Typography>}
          {!reportMode && <Button onClick={() => setReportMode(true)} variant="contained" sx={{ p: '10px 30px' }}>Report
              problem</Button>}
        </Grid>
        {reportMode
        && (
          <Grid>
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid component="form" onSubmit={handleSubmit(onSubmit)} className={styles.inputs} sx={{ mt: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        className={styles.input}
                        required={false}
                        fullWidth
                        autoFocus
                        label="Person reporting problem"
                        id="name"
                        autoComplete="Person reporting problem"
                        {...register('name')}
                        error={!!errors.name}
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.name?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" color={errors.datetime ? 'error' : 'inherit'}>
                        The Date and time when the issue began
                      </Typography>
                      <FormControlLabel
                        {...register('datetime')}
                        className={styles.controllerInput}
                        control={(
                          <Controller
                            render={({ field: { onChange } }) => (
                              <DateTimePicker
                                mask="___/__/__ __:__ _M"
                                renderInput={(props: any) => <TextField required={false} fullWidth {...props} />}
                                value={issueDate}
                                // @ts-ignore
                                onChange={(newDate) => {
                                  onChange(newDate);
                                  setIssueDate(newDate);
                                }}
                                inputFormat="dd-MM-yyyy HH:mm"
                              />
                            )}
                            control={control}
                            name="datetime"
                          />
                        )}
                        label=""
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.datetime?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={styles.input}
                        required={false}
                        fullWidth
                        id="email"
                        label="Email Address"
                        autoComplete="Email Address"
                        {...register('email')}
                        error={!!errors.email}
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.email?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ m: 1 }} variant="subtitle1" color={errors.phoneNumber ? 'error' : 'inherit'}>
                        Correct format: 420 XXX XXX XXX
                      </Typography>
                      <TextField
                        className={styles.input}
                        required={false}
                        fullWidth
                        id="phoneNumber"
                        label="Phone number"
                        autoComplete="Phone number"
                        {...register('phoneNumber')}
                        error={!!errors.phoneNumber}
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.phoneNumber?.message}
                      </Typography>
                    </Grid>
                    {formType === 'Desk-GreenWay'
                      ? (
                        <>
                          <Grid item xs={12}>
                            <FormControlLabel
                              {...register('location')}
                              className={styles.controllerInput}
                              control={(
                                <Controller
                                  render={({ field: { onChange } }) => (
                                    <Autocomplete
                                      disablePortal
                                      id="combo-box-demo"
                                      options={['Slovakia', 'Poland']}
                                      fullWidth
                                      getOptionLabel={(option) => option}
                                      onChange={(_event, value) => {
                                        onChange(value);
                                        setLocation(value!);
                                      }}
                                      renderInput={(params) => <TextField {...params} label="Country" required/>}
                                    />
                                  )}
                                  control={control}
                                  name="location"
                                />
                              )}
                              label=""
                            />
                            <Typography variant="subtitle2" color="error">
                              {errors.location?.message}
                            </Typography>
                          </Grid>
                          {location === 'Slovakia' && (
                            <Grid item xs={12}>
                              <FormControlLabel
                                {...register('locationSk')}
                                className={styles.controllerInput}
                                control={(
                                  <Controller
                                    render={({ field: { onChange } }) => (
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={locations.sk}
                                        fullWidth
                                        getOptionLabel={(option) => option.name}
                                        onChange={(_event, value) => {
                                          onChange(value);
                                          // @ts-ignore
                                          setLocationSk(value!.name);
                                        }}
                                        renderInput={(params) => <TextField {...params} required label="Sk location"/>}
                                      />
                                    )}
                                    control={control}
                                    name="locationSk"
                                  />
                                )}
                                label=""
                              />
                              <Typography variant="subtitle2" color="error">
                                {errors.locationSk?.message}
                              </Typography>
                            </Grid>
                          )}
                          {location === 'Poland' && (
                            <Grid item xs={12}>
                              <FormControlLabel
                                {...register('locationPl')}
                                className={styles.controllerInput}
                                control={(
                                  <Controller
                                    render={({ field: { onChange } }) => (
                                      <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={locations.pl}
                                        fullWidth
                                        getOptionLabel={(option) => option.name}
                                        onChange={(_event, value) => {
                                          onChange(value);
                                          // @ts-ignore
                                          setLocationPl(value.name);
                                        }}
                                        renderInput={(params) => <TextField {...params} required label="Pl location"/>}
                                      />
                                    )}
                                    control={control}
                                    name="locationPl"
                                  />
                                )}
                                label=""
                              />
                              <Typography variant="subtitle2" color="error">
                                {errors.locationPl?.message}
                              </Typography>
                            </Grid>
                          )}
                        </>
                      )
                      : (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              className={styles.input}
                              margin="normal"
                              required={false}
                              fullWidth
                              label="Company name"
                              id="companyName"
                              autoComplete="Company name"
                              {...register('companyName')}
                              error={!!errors.companyName}
                            />
                            <Typography variant="subtitle2" color="error">
                              {errors.companyName?.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              className={styles.input}
                              margin="normal"
                              required={false}
                              fullWidth
                              label="Site name"
                              id="siteName"
                              autoComplete="Site name"
                              {...register('siteName')}
                              error={!!errors.siteName}
                            />
                            <Typography variant="subtitle2" color="error">
                              {errors.siteName?.message}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    <Grid item xs={12}>
                      <FormControlLabel
                        {...register('device')}
                        className={styles.controllerInput}
                        control={(
                          <Controller
                            render={({ field: { onChange } }) => (
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={['Hardware', 'Software']}
                                fullWidth
                                getOptionLabel={(option) => option}
                                onChange={(_event, value) => {
                                  onChange(value);
                                  setDevice(value);
                                }}
                                renderInput={(params) => <TextField required {...params} label="Type of problem"/>}
                              />
                            )}
                            control={control}
                            name="device"
                          />
                        )}
                        label=""
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.device?.message}
                      </Typography>
                    </Grid>

                    {device === 'Hardware' && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          className={styles.controllerInput}
                          {...register('deviceHw')}
                          control={(
                            <Controller
                              render={({ field: { onChange } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  options={devices.hw}
                                  fullWidth
                                  getOptionLabel={(option) => option.name}
                                  onChange={(_event, value) => {
                                    onChange(value);
                                    // @ts-ignore
                                    setDevicesHw(value!.name);
                                  }}
                                  renderInput={(params) => <TextField {...params} required label="Hardware component"/>}
                                />
                              )}
                              control={control}
                              name="deviceHw"
                            />
                          )}
                          label=""
                        />
                        <Typography variant="subtitle2" color="error">
                          {errors.deviceHw?.message}
                        </Typography>
                      </Grid>
                    )}
                    {device === 'Software' && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          {...register('deviceSw')}
                          className={styles.controllerInput}
                          control={(
                            <Controller
                              render={({ field: { onChange } }) => (
                                <Autocomplete
                                  disablePortal
                                  id="combo-box-demo"
                                  options={devices.sw}
                                  fullWidth
                                  getOptionLabel={(option) => option.name}
                                  onChange={(_event, value) => {
                                    onChange(value);
                                    // @ts-ignore
                                    setDevicesSw(value!.name);
                                  }}
                                  renderInput={(params) => <TextField {...params} required label="Software component"/>}
                                />
                              )}
                              control={control}
                              name="deviceSw"
                            />
                          )}
                          label=""
                        />
                        <Typography variant="subtitle2" color="error">
                          {errors.devicesSw?.message}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        className={styles.input}
                        margin="normal"
                        required={false}
                        fullWidth
                        label="Problem title"
                        id="Subject"
                        autoComplete="Problem title"
                        {...register('subject')}
                        error={!!errors.subject}
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.subject?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={styles.input}
                        multiline
                        rows={10}
                        maxRows={30}
                        margin="normal"
                        required={false}
                        fullWidth
                        label="Description"
                        id="Description"
                        autoComplete="Description"
                        {...register('description')}
                        error={!!errors.description}
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.description?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl error={!!errors.file?.message}>
                        <FormControlLabel
                          control={(
                            <Controller
                              {...register('file')}
                              render={({ field: { onChange } }) => (
                                <Button
                                  variant="contained"
                                  component="label"
                                  sx={{
                                    p: '10px 30px', mt: 2, ml: 4, mb: 2,
                                  }}
                                >
                                  <input
                                    type="file"
                                    hidden
                                    required={false}
                                    onChange={(e) => {
                                      onChange(e.target.files);
                                      if (file.length >= 4) {
                                        setFileError(true)
                                      } else
                                      {
                                        setFile([...file, e.target.files![0]]);
                                      }
                                    }}
                                    multiple
                                  />
                                  Add attachment
                                </Button>
                              )}
                              control={control}
                              name="file"
                            />
                          )}
                          label=""
                        />
                      </FormControl>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">* (Photo, logs, Screentshot)</Typography>
                      </Grid>

                      <Grid sx={{ m: 2 }}>
                        {file.length > 0 &&
                        <>
                            <Typography variant="subtitle1">Added attachment:</Typography>
                            <Divider/>
                        </>
                        }
                        {
                          file?.map((item: any) => {
                            return <Grid className={styles.file}>
                              <Typography variant="subtitle1">{item?.name}</Typography>
                              <Button
                                onClick={() => {
                                  setFile(file.filter((i: any) => i.name !== item.name))
                                }}><ClearIcon/></Button>
                            </Grid>
                          })
                        }
                        {fileError && 'You can upload up to 5 files'}
                      </Grid>
                      <Typography variant="subtitle2" color="error">
                        {errors.file?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                      <img src={captchaUrl} className={styles.captchaImg} alt=""/>
                      <TextField
                        margin="normal"
                        required={false}
                        fullWidth
                        label="Please enter code from image"
                        id="captcha"
                        autoComplete="Please enter code from image"
                        {...register('captcha')}
                        error={!!errors.captcha}
                      />
                      <Typography variant="subtitle2" color="error">
                        {errors.captcha?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ p: '12px 40px' }}
                        disabled={loading}
                      >
                        {loading && 'Loading...'}
                        {!loading && 'Submit'}
                      </Button>
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
