import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Email is invalid')
    .max(40, 'Email must not exceed 40 characters'),
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name must be at least 2 characters')
    .max(20, 'Name must not exceed 20 characters'),
  datetime: Yup.date()
    .required('Issue\'s date is required'),
  captcha: Yup.string()
    .required('Captcha is required'),
  subject: Yup.string()
    .required('Subject is required')
    .min(10, 'Subject must be at least 10 characters')
    .max(500, 'Subject must not exceed 50 characters'),
  description: Yup.string()
    .required('Description is required')
    .min(10, 'Description must be at least 10 characters')
    .max(2000, 'Description must not exceed 2000 characters'),
  file: Yup.mixed().required('File is required'),
  companyName: Yup.string()
    .nullable()
    .notRequired()
    .when('companyName', {
      is: (value: string | any[]) => value?.length,
      then: (rule) => rule.min(3, 'Company name must be at least 3 characters').max(10, 'Company name must not exceed 30 characters'),
    }),
  siteName: Yup.string()
    .nullable()
    .notRequired()
    .when('siteName', {
      is: (value: string | any[]) => value?.length,
      then: (rule) => rule.min(5, 'Site name must be at least 5 characters').max(30, 'Site name must not exceed 30 characters'),
    }),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  location: Yup.object().nullable(),
  locationSk: Yup.object().nullable(),
  locationPl: Yup.object().nullable(),
  device: Yup.object().nullable(),
  deviceHw: Yup.object().nullable(),
  deviceSw: Yup.object().nullable(),
}, [
  ['companyName', 'companyName'],
  ['siteName', 'siteName'],
]);
