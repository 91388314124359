export const transformDateToDays = (date: Date) => {
  let day = date.getDate() as string | number;
  let month = date.getMonth() + 1 as string | number;
  const year = date.getFullYear();
  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;
  return `${month}.${day}.${year}`;
};

export const transformDateToHours = (date: Date): string => {
  let hours = (date.getHours()) as string | number;
  if (hours > 12) {
    hours = Number(hours) - 12;
  }
  if (hours < 10) hours = `0${hours}`;
  hours = String(hours);
  return hours;
};

export const dateFormat = (date: Date): string => {
  const hours = (date.getHours()) as string | number;
  if (hours > 12) {
    return 'PM';
  }
  return 'AM';
};

export const transformDateToMinutes = (date: Date): string => {
  let minutes = date.getMinutes() as string | number;
  if (minutes < 10) minutes = `0${minutes}`;
  minutes = String(minutes);
  return minutes;
};
